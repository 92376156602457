import React, { useState } from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { TwitterShareButton, LinkedinShareButton, FacebookShareButton } from 'react-share';
import useSiteMetadata from 'hooks/useSiteMetaData';
// import { Salary } from '../helpers';
//styles
import styles from './job-overview.module.css';
//types
import { TPosition } from '../open-positions';
//icons
import MapIcon from '../../../images/career/map-icon.inline.svg';
import TechIcon from '../../../images/career/tech-icon.inline.svg';
import CopyLinkLogo from '../../../images/icons/share-link-light.inline.svg';
//assets
const { setPositionId } = require('../setPositionId');

const Routes = require('../../routes').Routes;

interface IPositionOverview {
  positionOverview: TPosition;
  listOfLinks?: { title: string; to: string; logo: JSX.Element }[];
  isModal?: boolean;
  goBack?: boolean;
  onApplyClick: () => void;
}

function JobOverView({
  positionOverview,
  isModal = false,
  goBack = false,
  onApplyClick,
}: IPositionOverview) {
  const {
    id,
    position_title: title,
    project_overview: overview,
    city,
    remote,
    responsibilities,
    requirements,
    nice_to_have: advance,
    // higher_education: education,
    what_we_offer: offer,
    // salary,
    stack_of_technologies: technologies,
  } = positionOverview;

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const positionId = setPositionId(id, title);

  const linkForCopy = `${process.env.GATSBY_PUBLIC_URL}${Routes.CAREER}${positionId}/`;

  const breakpoint = useBreakpoint();

  const { siteUrl } = useSiteMetadata();

  const shareUrl = `${siteUrl}${Routes.CAREER}${positionId}/`;

  return (
    <div className={styles.viewContainer}>
      {!!goBack && (
        <Link to={`${Routes.CAREER}#current-open-positions`} className={styles.back}>
          Back to Open Positions
        </Link>
      )}
      {!!isModal && <p className={styles.capture}>Position</p>}
      <p className={styles.captureInfo}>{title}</p>
      <div className={styles.extraInfo}>
        {/* {!!salary && (
          <div className={styles.salaryBox}>
            <Salary currency={salary.currency} min={salary.min} max={salary.max} className={styles.salary}/>
          </div>
        )} */}
        {!!technologies && technologies?.length > 0 && (
          <div className={styles.techStack}>
            <TechIcon className={styles.iconTech} />
            <ul className={styles.techList}>
              {technologies.map(({ id, technology_name }) => (
                <li key={id} className={styles.techItem}>
                  {technology_name}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* {!!remote && (
          <div className={styles.location}>
            <MapIcon /> <span className={styles.extra}>Remote</span>
          </div>
        )} */}
        {(!!city || !!remote) && <MapIcon />}
        {!!city && !!remote ? (
          <span className={styles.extra}>{city}, Remote</span>
        ) : (
          <>
            {!!city && <span className={styles.extra}>{city}</span>}
            {!!remote && <span className={styles.extra}>Remote</span>}
          </>
        )}
      </div>
      <div
        className={
          isModal
            ? clsx(styles.linkToShare, styles.linkOnModal)
            : clsx(styles.linkToShare, styles.linkOnPage)
        }
      >
        <ul className={styles.listOfLinks}>
          <FacebookShareButton url={shareUrl}>
            <span className={styles.fb} />
          </FacebookShareButton>
          <LinkedinShareButton url={shareUrl}>
            <span className={styles.li} />
          </LinkedinShareButton>
          <TwitterShareButton url={shareUrl}>
            <span className={styles.tw} />
          </TwitterShareButton>
          <li key="copy to cleapboard" className={styles.linkItem}>
            <button
              className={styles.copyUrl}
              // aria-label="copy to cleapboard"
              onClick={() => {
                navigator.clipboard.writeText(linkForCopy);
                setIsLinkCopied(true);
              }}
              data-tip
              data-for="Copy link"
            >
              <CopyLinkLogo />
            </button>
          </li>
        </ul>
      </div>
      <button className={styles.btnApply} onClick={onApplyClick}>
        Apply now
      </button>
      {overview && (
        <>
          <p className={styles.title}>Project Overview</p>
          <ReactMarkdown className={styles.text} children={overview} skipHtml={false} />
        </>
      )}
      {responsibilities && (
        <>
          <p className={styles.title}>Responsibilities</p>
          <ReactMarkdown className={styles.text} children={responsibilities} skipHtml={false} />
        </>
      )}
      {requirements && (
        <>
          <p className={styles.title}>Requirements</p>
          <ReactMarkdown className={styles.text} children={requirements} skipHtml={false} />
        </>
      )}
      {advance && (
        <>
          <p className={styles.title}>Nice to have</p>
          <ReactMarkdown className={styles.text} children={advance} skipHtml={false} />
        </>
      )}
      {/* {education && (
        <>
          <p className={styles.title}>Higher Education</p>
          <ReactMarkdown className={styles.text} children={education} skipHtml={false} />
        </>
      )} */}
      {offer && (
        <>
          <p className={styles.title}>What we offer</p>
          <ReactMarkdown
            className={clsx(styles.text, styles.lastText)}
            children={offer}
            skipHtml={false}
          />
        </>
      )}
      {goBack && (
        <Link to={`${Routes.CAREER}#current-open-positions`} className={styles.back}>
          Back to Open Positions
        </Link>
      )}
      {!breakpoint.s && (
        <ReactTooltip
          id="Copy link"
          place="bottom"
          type="light"
          effect="solid"
          role="tooltip"
          textColor="#ffffff"
          backgroundColor="#1e2126"
          scrollHide={true}
        >
          <span>{isLinkCopied ? 'Copied!' : 'Copy link'}</span>
        </ReactTooltip>
      )}
    </div>
  );
}

export default JobOverView;
