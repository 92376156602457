import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
//styles
import styles from './header.module.css';

interface IHeader {
  title: string;
}

function Header({ title }: IHeader) {
  const data = useStaticQuery(graphql`
    query {
      bgImg: file(relativePath: { eq: "job-details.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      Tag="section"
      fluid={data.bgImg.childImageSharp.fluid}
      className={styles.bg}
      preserveStackingContext
    >
      <header className={styles.container}>
        <div className="inner">
          <div className={styles.content}>
            <p className={styles.capture}>Position</p>
            <p className={styles.title}>{title}</p>
          </div>
        </div>
      </header>
    </BackgroundImage>
  );
}

export default Header;
