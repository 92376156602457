import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { meta } from '../../../metaData';
import loadable from '@loadable/component';
//styles
import styles from './job-details.module.css';
//components
import Layout from 'components/layout';
import Header from './header';
import JobOverView from '../job-overview';
// import ApplicationForm from '../application-form';
import Footer from 'components/layout/footer';
//types
import { TPosition } from '../open-positions';

interface IPosition {
  pathContext: { data: TPosition };
}

const ApplicationForm = loadable(() => import('../application-form'));

const JobDetails = ({ pathContext }: IPosition) => {
  const { position_title: title } = pathContext.data;
  const applyContainer = useRef<null | HTMLDivElement>(null);

  const capchaRefDetails = useRef<ReCAPTCHA>(null);

  return (
    <Layout title="Details" navBtnTextColor="#14181A" metaData={{ main: meta.career }}>
      <Header title={title} />

      <section className={styles.overview}>
        <JobOverView
          positionOverview={pathContext.data}
          goBack={true}
          onApplyClick={() => {
            if (applyContainer.current) {
              applyContainer.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
          }}
        />
      </section>

      <div className={styles.form} id="join-now" ref={applyContainer}>
        <div className={styles.formHead}>
          <p className={styles.captureInfo}>{title}</p>
          <span className={styles.letWork}>Let’s get to work together</span>

          <a className={styles.mail} href="mailto:join@brocoders.team">
            join@brocoders.team
          </a>
        </div>
        <ApplicationForm positions={[pathContext.data]} refForRecaptcha={capchaRefDetails} />
      </div>
      <Footer />
    </Layout>
  );
};
export default JobDetails;
